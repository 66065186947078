.review {
    @apply bg-red-100 border-x-red-200;
}

tr:hover .review {
    @apply bg-red-200 border-x-red-300;
}

.mat-column-documents {
    text-align: center !important;
}

.filters .mat-form-field-wrapper
{
    padding-bottom: 0px !important;
}
